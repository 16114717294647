/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {
  $(document).ready(function(){
    $("a[rel^='prettyPhoto']").prettyPhoto({social_tools: false});
  });

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

        function setJobCookie() {
          var cookieDate = new Date();
          cookieDate.setTime(cookieDate.getTime() + (30*24*60*60*1000));
          var expires = "expires="+ cookieDate.toUTCString();
          document.cookie = 'jobPopup' + "=" + 'seen' + ";" + expires + ";path=/";
        }

        $('.flexible-faqs-content button').click(function() {
          $(this).toggleClass('active');
        });
        $("select").selectBoxIt();

        //We're hiring popup
        if ($('.homepage-banner, .comp-page-header').length) {
          var topOfBanner = $('.homepage-banner, .comp-page-header').offset().top;
          var bannerHeight = $('.homepage-banner, .comp-page-header').outerHeight() / 2;
          $(window).scroll(function () {
            if ($(window).scrollTop() > (topOfBanner + bannerHeight)) {
              $('.jobs-popup').addClass('slide-out');
            }
          });

          $('.jobs-popup-button .popup-text').click(function () {
            $('.jobs-popup').removeClass('slide-out');
          });

          $('.jobs-popup .popup-text .close-icon').click(function () {
            $('.jobs-popup').addClass('slide-out');
          });
        }

        //Contact Popup
        $('.contact-trigger').click(function(e) {
          e.preventDefault();
          $('html, body').addClass('freeze');
          $('.partial-contact-popup').addClass('open');
          var pageTitle = $('body').attr('data-page-title');
          $('#nf-form-9-cont .hidden-container input').val(pageTitle);
          $('.partial-contact-popup').fadeIn();
        });

        $('.partial-contact-popup p.close-form').click(function() {
          $('html, body').removeClass('freeze');
          $('.partial-contact-popup').removeClass('open');
          $('.partial-contact-popup').fadeOut();
        });

        $('.application-trigger').click(function(e) {
          e.preventDefault();
          $('html, body').addClass('freeze');
          var pageTitle = $('body').attr('data-page-title');
          $('#nf-form-10-cont .hidden-container input').val(pageTitle);
          $('.partial-application-popup').addClass('open');
          $('.partial-application-popup').fadeIn();
        });

        $('.partial-application-popup p.close-form').click(function() {
          $('html, body').removeClass('freeze');
          $('.partial-application-popup').removeClass('open');
          $('.partial-application-popup').fadeOut();
        });

        if ($('.projects-slider').length) {
          $('.projects-slider .slides-container').slick({
            infinite: true,
            slidesToShow: 3,
            slidesToScroll: 1,
            dots: false,
            prevArrow: $('.projects-slider .left-arrow'),
            nextArrow: $('.projects-slider .right-arrow'),
            responsive: [
              {
                breakpoint: 992,
                settings: {
                  slidesToShow: 1
                }
              }
            ]
          });
        }

        if ($('.articles-slider').length) {
          $('.articles-slider .slides-container').slick({
            infinite: true,
            slidesToShow: 3,
            slidesToScroll: 1,
            dots: false,
            arrows: false,
            responsive: [
              {
                breakpoint: 992,
                settings: {
                  slidesToShow: 1
                }
              }
            ]
          });
        }

        if ($('.jobs-slider').length) {
          $('.jobs-slider .slides-container').slick({
            infinite: true,
            slidesToShow: 3,
            slidesToScroll: 1,
            dots: false,
            arrows: false,
            responsive: [
              {
                breakpoint: 992,
                settings: {
                  slidesToShow: 1
                }
              }
            ]
          });
        }

        if ($('.quotes-slider').length) {
          $('.quotes-slider').slick({
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            dots: false,
            arrows: false,
            swipe: false,
          });

          $('.quote-nav .image').click(function() {
            $('.quote-nav .image').removeClass('active');
            $(this).addClass('active');
            var slideno = $(this).data('slide');
            $('.quotes-slider').slick('slickGoTo', slideno);
          });
        }
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
        $("img.bounce").click(function() {
          $('html,body').animate({
                scrollTop: $(".introduction-text").offset().top-70
          }, 'slow');
        });
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);


})(jQuery); // Fully reference jQuery after this point.

//Assign first introduction text block the active class for bootstrap
jQuery('.introduction-text .item:first').addClass('active');

//Assign first introduction text block pagination the active class for bootstrap
jQuery('.carousel-indicators li:first').addClass('active');

//Toggling the Read More dropdown
jQuery('.item-container .read-more-buttons').click(function() {
  if(jQuery(this).is(':contains("READ LESS")')) {
    console.log('less');
    jQuery('#myCarousel').carousel('cycle');
  }
});

jQuery('.item-container .read-more-buttons').click(function() {
  if(jQuery(this).is(':contains("READ MORE")')) {
    console.log('more');
    jQuery('#myCarousel').carousel('pause');
  }
});

jQuery('.item-container .read-more-buttons').click(function() {
  jQuery('.hidden-text').slideToggle( "slow", function() {});
  jQuery(this).children('a').text(function(i, text){
    return text === "READ MORE" ? "READ LESS" : "READ MORE";
  });
});

jQuery('.clicky').click(function() {
  jQuery('.navbar-fixed-top').fadeOut('slow');
});

jQuery(document).scroll(function() {
  var y = jQuery(this).scrollTop();
  if (jQuery('body').hasClass('page-template-template-careers-flex') || jQuery('body').hasClass('page-template-template-job-listings')) {
    if (jQuery('.careers-nav-holder').length) {
      if (jQuery(window).width() > 991) {
        yCompare = '75';
      } else {
        yCompare = '55';
      }
      if (y > yCompare) {
        jQuery('.careers-nav').addClass('stuck');
      } else {
        jQuery('.careers-nav').removeClass('stuck');
      }
    }
  } else if(jQuery('body').hasClass('page-template-template-generic-page') || jQuery('body').hasClass('single-jobs')) {
    if (jQuery('.careers-nav-holder').length) {
      if (jQuery(window).width() > 991) {
        yCompare = '75';
      } else {
        yCompare = '55';
      }
      if (y > yCompare) {
        jQuery('.careers-nav').addClass('stuck');
      } else {
        jQuery('.careers-nav').removeClass('stuck');
      }
    } else {
      if (y > 50) {
        jQuery('.navbar-fixed-top').fadeIn();
        jQuery('.menu-opened').click(function () {
          jQuery('.navbar-fixed-top').fadeIn('slow');
        });
      } else {
        jQuery('.navbar-fixed-top').fadeOut();
      }
    }
  } else {
    if (y > 50) {
      jQuery('.navbar-fixed-top').fadeIn();
      jQuery('.menu-opened').click(function () {
        jQuery('.navbar-fixed-top').fadeIn('slow');
      });
    } else {
      jQuery('.navbar-fixed-top').fadeOut();
    }
  }
});

jQuery('.nav-primary img, .nav-primary p, .white-menu, .blue-menu').click(function() {
  jQuery('body').toggleClass('hide-overflow');
  jQuery('.menu-menu-1-container').fadeToggle();
  jQuery('.menu-opened').fadeToggle();
});


jQuery(window).on('load resize', function(){
  var grid = jQuery('.grid-boxes').height();
  var height = grid / 2;
  jQuery('.introduction-grid, .download-grid').css('max-height', height);
});

jQuery('.flexible-faqs-content button').click(function() {
  jQuery(this).find('span').toggleClass('opened');
});

function gridBoxHeight() {
  var gridBoxHeight = jQuery('.static-section .archive-repeater-grid').outerHeight();
  var tt = jQuery('.tweets-title').outerHeight();
  var oHeight = parseInt(gridBoxHeight / 2);
  var tHeight = parseInt(oHeight - tt);
  jQuery('.fetch-tweets-item ').height(oHeight);
  jQuery('.fetch-tweets-item:first-child').height(tHeight);
}

gridBoxHeight();

jQuery('.header-container-static nav ul li').click(function() {
  jQuery(this).find('.sub-menu').slideToggle('slow');
});
