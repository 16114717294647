"use strict";

(function ($) {
    app.loadMore = {
        init: function init() {
            var $loadMoreHandler = $('.load-more-container');
            var $loadMoreBody = $loadMoreHandler.find('.load-more-body');
            $loadMoreHandler.on('load-more.run', function (e, reset) {
                reset = reset !== undefined ? reset : false;
                var $loadMoreBtn = $loadMoreHandler.find('.load-more-btn');
                var page = $loadMoreBtn.data('page'); // // Prevent multiple clicks at a time

                if ($loadMoreHandler.data('loading')) {
                    return;
                }

                $loadMoreHandler.data('loading', true); // If resetting, then make sure to empty the container of existing items

                if (reset) {
                    $loadMoreBody.html(app.loadMore.loader);
                    page = 1;
                } else {
                    // Disable button and show loader
                    $loadMoreBtn.addClass('disabled');
                    $loadMoreBtn.html('Loading...');
                }

                var params = $.mergeObjects($loadMoreHandler.data('filters'), {
                    action: 'app_load_more',
                    paged: page,
                    loop: $loadMoreHandler.data('type')
                });
                $.get(app.url.ajax, params).done(function (response) {
                    // Remove loader if resetting or loader button
                    if (reset) {
                        $loadMoreBody.html('');
                    } else {
                        $loadMoreBtn.parent().remove();
                    } // Re-enable filters form


                    if ($($loadMoreHandler.data('form')).length) {
                        $($loadMoreHandler.data('form')).find('fieldset').prop('readonly', false);
                    } // Append results


                    $loadMoreBody.append(response); // End loading status

                    $loadMoreHandler.data('loading', false); // Add event trigger for completion

                    $loadMoreHandler.trigger('load-more.complete');

                    $('.comp-job-listing').each(function() {
                        if ($(this).visible(true)) {
                            var time = 500;
                            $(this).find('.single-career, p, h1, h2, h3, h4, h5, h6').each(function (index) {
                                var self = this;
                                setTimeout(function () {
                                    $(self).addClass('visible-show');
                                }, index * 200);
                            });
                        }
                    });
                });
            });
            $loadMoreHandler.on('click', app.loadMore.buttonSelector, function (e) {
                e.preventDefault();
                $loadMoreHandler.trigger('load-more.run');
            });

            if ($('#posts-filters').length) {
                $('#posts-filters input').on('change', function (e) {
                    $('#posts-filters label').removeClass('active');
                    $(this).parent('label').addClass('active');
                    $('#posts-filters').submit();
                });
            }

            $($loadMoreHandler.data('form')).on('submit', function (e) {
                e.preventDefault();
                var $this = $(this); // Prevent multiple clicks at a time

                if ($loadMoreHandler.data('loading')) {
                    return;
                }

                $this.find('fieldset').prop('readonly', true);
                $loadMoreHandler.data('filters', $this.serializeObject());
                $loadMoreHandler.trigger('load-more.run', [true]);
            });

            if ($loadMoreHandler.data('form')) {
                var $form = $($loadMoreHandler.data('form'));
                $form.on('click', '.load-more-reset', function (e) {
                    e.preventDefault();
                    $('#posts-filters label').removeClass('active');
                    $(this).parent('label').addClass('active'); // Prevent multiple clicks at a time

                    if ($loadMoreHandler.data('loading')) {
                        return;
                    } // Reset the filters form and submit


                    $form[0].reset();
                    $form.submit();
                });
                $loadMoreHandler.data('filters', $form.serializeObject());
            }
        },
        buttonSelector: '.load-more-btn',
        loader: '<div class="zoo-loader-container"><div class="zoo-loader" id="zoo-loader"><span></span><span></span><span></span></div></div>'
    };
    app.loadMore.init();
})(jQuery);