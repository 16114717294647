(function($) {
    var aniTimer = 0.5;

    //Page Headers Comp
    var compPageHeader = $('.comp-page-header');
    function pageHeaderAnimations() {
        compPageHeader.each(function() {
            if ($(this).visible(true)) {
                var time = 500;
                $(this).find('p, h1, h2, h3, h4').each(function (index) {
                    var self = this;
                    setTimeout(function () {
                        $(self).addClass('visible-show');
                    }, index * 200);
                });
            }
        });
    }

    pageHeaderAnimations();
    $(window).scroll(function () {
        pageHeaderAnimations();
    });

    //Text Image Left Right Comp
    var compTILR = $('.comp-left-right-text, .comp-featured-quotes');
    function tilrAnimations() {
        compTILR.each(function() {
            if ($(this).visible(true)) {
                var time = 500;
                $(this).find('.image-area .image').addClass('visible-show-image');
                $(this).find('p, h1, h2, h3, h4, h5, h6, .quote-nav .image').each(function (index) {
                    var self = this;
                    setTimeout(function () {
                        $(self).addClass('visible-show');
                    }, index * 200);
                });
            }
        });
    }

    tilrAnimations();
    $(window).scroll(function () {
        tilrAnimations();
    });

    //Featured Text Comp
    if ($(window).width() > 991) {
        var visible = false;
    } else {
        var visible = true;
    }
    var compFeaturedText = $('.comp-featured-text');
    function featuredTextAnimations(visible) {
        compFeaturedText.each(function(visible) {
            if ($(this).visible(visible)) {
                var time = 500;
                $(this).find('.background-image').addClass('visible');
                $(this).find('p, h1, h2, h3, h4, h5, h6').each(function (index) {
                    var self = this;
                    setTimeout(function () {
                        $(self).addClass('visible-show');
                    }, index * 200);
                });
            }
        });
    }

    featuredTextAnimations();
    $(window).scroll(function () {
        featuredTextAnimations();
    });

    //Projects Comp
    var compProjects = $('.comp-projects');
    function projectsAnimations() {
        compProjects.each(function() {
            if ($(this).visible(true)) {
                var time = 500;
                $(this).find('.article, p, h1, h2, h3, h4, h5, h6').each(function (index) {
                    var self = this;
                    setTimeout(function () {
                        $(self).addClass('visible-show');
                    }, index * 200);
                });
            }
        });
    }

    projectsAnimations();
    $(window).scroll(function () {
        projectsAnimations();
    });

    //Information Block Comp
    var compInformationBlock = $('.comp-information-block');
    function informationBlockAnimations() {
        compInformationBlock.each(function() {
            if ($(this).visible(true)) {
                var time = 500;
                $(this).find('.image').addClass('visible-show');
                $(this).find('.article, p, h1, h2, h3, h4, h5, h6').each(function (index) {
                    var self = this;
                    setTimeout(function () {
                        $(self).addClass('visible-show');
                    }, index * 200);
                });
            }
        });
    }

    informationBlockAnimations();
    $(window).scroll(function () {
        informationBlockAnimations();
    });

    //EASL News Comp
    var compEaslNews = $('.comp-easl-news');
    function easlNewsAnimations() {
        compEaslNews.each(function() {
            if ($(this).visible(true)) {
                var time = 500;
                $(this).find('.article, p, h1, h2, h3, h4, h5, h6').each(function (index) {
                    var self = this;
                    setTimeout(function () {
                        $(self).addClass('visible-show');
                    }, index * 200);
                });
            }
        });
    }

    easlNewsAnimations();
    $(window).scroll(function () {
        easlNewsAnimations();
    });

    //Current Vacancies Comp
    var compCurrentVacancies = $('.comp-current-vacancies');
    function currentVacanciesAnimations() {
        compCurrentVacancies.each(function() {
            if ($(this).visible(true)) {
                var time = 500;
                $(this).find('.job, p, h1, h2, h3, h4, h5, h6').each(function (index) {
                    var self = this;
                    setTimeout(function () {
                        $(self).addClass('visible-show');
                    }, index * 200);
                });
            }
        });
    }

    currentVacanciesAnimations();
    $(window).scroll(function () {
        currentVacanciesAnimations();
    });

    //Job Listing
    var jobListing = $('.comp-job-listing');
    function jobListAnimations() {
        jobListing.each(function() {
            if ($(this).visible(true)) {
                var time = 500;
                $(this).find('.single-career, p, h1, h2, h3, h4, h5, h6').each(function (index) {
                    var self = this;
                    setTimeout(function () {
                        $(self).addClass('visible-show');
                    }, index * 200);
                });
            }
        });
    }

    jobListAnimations();
    $(window).scroll(function () {
        jobListAnimations();
    });
    $('#posts-filters label').click(function() {
        jobListAnimations();
    });
})(jQuery);