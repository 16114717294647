"use strict";

// jQuery - Merge two objects
//
// Usage: $.mergeObjects(object, object2)
jQuery.mergeObjects = function (object, object2) {
    return jQuery.extend({}, object, object2 || {});
}; // jQuery - Merge two objects
//
// Usage: $.mergeObjects(object, object2)


jQuery.fn.serializeObject = function () {
    var data = {};
    jQuery(this).serializeArray().map(function (field) {
        if (!field.value) {
            return;
        }

        if (data.hasOwnProperty(field.name)) {
            if (Array.isArray(data[field.name])) {
                data[field.name].push(field.value);
            } else {
                data[field.name] = [data[field.name], field.value];
            }
        } else {
            data[field.name] = field.value;
        }
    });
    return data;
}; // jQuery - Check if target element is in view.
//
// Usage: $(sel).inview({[bottom: true|false], [...] })


jQuery.fn.inview = function (options) {
    options = jQuery.mergeObjects({
        bottom: false,
        both: false,
        callback: null,
        container: window,
        offset: 0
    }, options);
    var $this = jQuery(this);

    if (!$this.length) {
        return;
    }

    if ($this.length > 1) {
        $this.each(function () {
            jQuery(this).inview(options);
        });
        return;
    }

    var targetY = $this.offset().top;
    var scrollY = jQuery(options.container).height() + jQuery(options.container).scrollTop();

    if ($this.data('iv-offset')) {
        options.offset = $this.data('iv-offset');
    } // Don't trigger if the element isn't visible


    if (!$this.is(':visible')) {
        return;
    } // Include offset if required


    if (options.offset) {
        // Check if offset is percent (and assume of window)
        if (/^\d+(\.\d+)?%$/.test(options.offset)) {
            options.offset = jQuery(window).height() * (parseFloat(options.offset) / 100.0);
        }

        targetY += options.offset;
    }

    var isInViewTop = scrollY >= targetY;
    var isInViewBottom = scrollY <= targetY + jQuery(this).height();

    if (typeof options.callback === "function") {
        return options.callback($this, isInViewTop && isInViewBottom, isInViewTop, isInViewBottom);
    }

    if (options.bottom) {
        return isInViewBottom;
    } else if (options.both) {
        isInViewTop && isInViewBottom;
    }

    return isInViewTop;
};

window.deviceWidth = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);